<template>
  <div>
    <div class="mb-6">
      <a
        class="text-decoration-none text-subtitle-2 font-weight-bold"
        @click.prevent="returnBack"
      >
        <v-icon
          color="primary"
          dense
          class="mr-2"
        >
          {{ icons.mdiArrowLeft }}
        </v-icon>
        Kembali
      </a>
    </div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          title="Guru Paling Aktif"
          subtitle="Daftar guru paling aktif"
          :no-add-button="noAddButton"
          :headers="headers"
          :items="teachers"
          :search="search"
          :page="page"
          :total-items="totalItems"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          @live-search="searchHandler"
          @change-page="paginationHandler"
        ></DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
  </div>
</template>

<script>
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import { mdiArrowLeft } from '@mdi/js'

export default {
  name: 'BookPurchaseHistory',
  components: {
    MainCard,
    DataTablePagination,
    SkeletonLoaderTable,
  },
  data() {
    return {
      icons: {
        mdiArrowLeft,
      },
      noAddButton: true,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Nama', value: 'photo-user' },
        { text: 'Asal Sekolah', value: 'school' },
        { text: 'Aktifitas', value: 'activity' },
        { text: 'E-Learning', value: 'elearning' },
        { text: 'Bank Soal', value: 'question_bank' },
      ],
      teachers: [],
      page: 1,
      search: '',
      service: 'teacheractive',
      totalItems: 10,
      totalPages: 0,
      isLoadingData: false,
      isLoadingTable: true,
      filterQuery: {
        search: '',
      },
    }
  },
  watch: {
    page: {
      handler() {
        this.listActiveTeacher()
      },
    },
    search: {
      handler() {
        this.listActiveTeacher()
      },
    },
  },
  mounted() {
    this.listActiveTeacher()
  },
  methods: {
    async listActiveTeacher(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, {
        ...params,
        page: this.page,
      }).then(({ data }) => {
        this.teachers = data.data.map((teacher, index) => ({
          ...teacher,
          index: index + 1,
        }))
        this.totalItems = data.meta.total
        this.totalPages = data.meta.last_page
      })
      this.isLoadingData = false
      this.isLoadingTable = false
    },
    async searchHandler(search) {
      this.filterQuery.search = search
      this.page = 1
      await this.listActiveTeacher(this.filterQuery)
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    returnBack() {
      this.$router.back()
    },
  },
}
</script>

<style>
</style>
